<template>
    <div>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card :header="$t('education_info')" class="soft mb-5">
                    <b-row>
                        <b-col cols="12" lg="12">
                            <b-table :empty-filtered-text="$t('no_result')"
                                     :empty-text="$t('no_result')"
                                     bordered
                                     responsive
                                     :items="graduateData.educations"
                                     :fields="educationFields"
                                     show-empty
                                     class="mb-4 table-dropdown no-scrollbar border rounded">
                                <template #cell(buttons)="row">
                                    <b-dropdown variant="link btn-sm" boundary="window" no-caret>
                                        <template #button-content>
                                            <i class="ri-more-fill"></i>
                                        </template>
                                        <div id="dropdownListHead">
                                            <b-dropdown-item @click="$emit('updateClick',['education_info','edit'],row)" v-if="checkPermission('documentrequest_sendebys')">
                                                <i class="ri-edit-box-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('edit')}}
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="deleteEducationInfo(row.id)" v-if="checkPermission('documentrequest_sendebys')">
                                                <i class="ri-delete-bin-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('delete')}}
                                            </b-dropdown-item>
                                        </div>
                                    </b-dropdown>
                                </template>
                            </b-table>
                            <b-row>
                                <b-col cols="auto">
                                    <b-button variant="primary" @click="$emit('updateClick',['education_info','add'])" class="mb-5"><i class="ri-add-line"></i> {{$t('add')}}</b-button>
                                </b-col>
                                <b-col cols="auto">
                                    <b-button variant="primary" @click="searchByYoksis">{{$t('search_by_yoksis')}}</b-button>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12" lg="12" class="mt5">
                            <div class="label-as-input-div">
                                <label>{{ $t('do_you_think_to_do_master_in_corp') }}</label>
                                <div>{{ graduateData.do_you_think_to_do_master_in_corp }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="12">
                            <b-button class="mt-1" variant="primary" @click="$emit('updateClick',['education_info'])">{{ $t('edit') }}</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <CommonModal ref="yoksisSearchModal" size="xl">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('yoksis_results').toUpper() }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <b-row v-if="yoksisSearchSwitch">
                        <b-col cols="12" class="d-flex justify-content-center">
                            <b-spinner variant="primary" label="Spinning"></b-spinner>
                        </b-col>
                        <b-col cols="12" class="text-center mt-2">
                            {{ $t('yoksis_searching') }}
                        </b-col>
                    </b-row>
                    <b-row v-if="!yoksisSearchSwitch">
                        <b-col cols="12">
                            <b-table :empty-filtered-text="$t('no_result')"
                                     :empty-text="$t('no_result')"
                                     bordered
                                     striped
                                     responsive
                                     :items="yoksisData"
                                     :fields="selectedEducationsFields"
                                     class="mb-0 table-dropdown no-scrollbar border rounded"
                                     ref="yoksisTable">
                                <template #cell(button)="row">
                                    <div>
                                        <b-button v-if="!row.item.added" @click="addToEducationsFromList(row)" variant="primary" size="sm" :title="$t('add')">
                                            <i class="ri-add-line"></i>
                                        </b-button>
                                        <b-button v-if="row.item.added" variant="success" size="sm" :title="$t('added')">
                                            <i class="ri-check-line"></i>
                                        </b-button>
                                    </div>
                                </template>
                            </b-table>
                        </b-col>
                        <b-col cols="12" class="mt-2">
                            <b-button @click="yoksisManuelSwitch = !yoksisManuelSwitch" variant="primary">{{ $t('add_info_manuel') }}</b-button>
                        </b-col>
                        <b-col v-show="yoksisManuelSwitch" cols="12">
                            <div class="border rounded mt-2 p-4">
                            <ValidationObserver ref="educationExamForm">
                                <b-row>
                                <b-col cols="12" md="4">
                                    <ValidationProvider name="graduationDegree" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('graduation_degree')">
                                            <parameter-selectbox v-model="educationExamForm.graduationDegree"
                                                                 code="graduate_application_graduation_degrees"
                                                                 :public="true"
                                                                 :validate-error="errors[0]"></parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <ValidationProvider name="university" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('graduation_university')">
                                            <university-selectbox
                                                :public="true"
                                                v-model="educationExamForm.university"
                                                :validate-error="errors[0]"></university-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <ValidationProvider name="faculty" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('faculty')">
                                            <b-form-input
                                                :state="errors[0] ? false : null"
                                                v-model="educationExamForm.faculty"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <ValidationProvider name="department" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('department')">
                                            <b-form-input
                                                :state="errors[0] ? false : null"
                                                v-model="educationExamForm.department"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <ValidationProvider name="year" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('graduation_year')">
                                            <years-selectbox
                                                :interval="40"
                                                v-model="educationExamForm.year"
                                                :validate-error="errors[0]"></years-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <ValidationProvider name="average" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('graduation_average')">
                                            <b-form-input
                                                v-mask="educationExamForm.averageSystem == 'four_system' ? floatMaskFour : floatMask"
                                                min="0.00"
                                                :max="educationExamForm.averageSystem == 'four_system' ? 4 : 100"
                                                v-check-min-max-value
                                                :state="errors[0] ? false : null"
                                                v-model="educationExamForm.average"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <b-form-group v-slot="{ ariaDescribedby }">
                                        <label class="mt-5"> </label>
                                        <ValidationProvider name="averageSystem" rules="required" v-slot="{valid, errors}">
                                            <b-form-radio-group v-model="educationExamForm.averageSystem" :aria-describedby="ariaDescribedby">
                                                <b-form-radio value="four_system">{{ $t('average_system_4') }}</b-form-radio>
                                                <b-form-radio value="hundred_system">{{ $t('average_system_100') }}</b-form-radio>
                                            </b-form-radio-group>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </ValidationProvider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" class="d-flex justify-content-center mt-4">
                                    <b-button @click="addToEducations" type="button" variant="primary">{{ $t('add').toUpper() }}</b-button>
                                </b-col>
                                </b-row>
                            </ValidationObserver>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </template>
        </CommonModal>
    </div>
</template>
<script>
// Template

// Components
import GraduateApplicationService from "@/services/GraduateApplicationService";
import YearsSelectbox from "@/components/interactive-fields/YearsSelectbox"


//Pages

// Services

// Others

export default {
    props: {
        graduateData: {
            type: Object,
            default: {}
        },
    },

    components: {
        YearsSelectbox
    },

    data() {
        return {
            educationFields: [
                {
                    key: 'buttons',
                    label: '',
                },
                {
                    key: 'education_status',
                    label: this.$t('education_status'),
                },
                {
                    key: 'university',
                    label: this.$t('school')+' / '+this.$t('university'),
                },
                {
                    key: 'field_program',
                    label: this.$t('field_program'),
                },
                {
                    key: 'graduate_date',
                    label: this.$t('graduate_date'),
                },
                {
                    key: 'diploma_number',
                    label: this.$t('diploma_number'),
                },
                {
                    key: 'diploma_point',
                    label: this.$t('diploma_point'),
                }
            ],
            educationExamForm: {
                graduationDegree: null,
                university: null,
                faculty: null,
                department: null,
                year: null,
                average: null,
                averageSystem: null
            },
            selectedEducationsFields: [
                {
                    key: 'university_name',
                    label: this.$t('university').toUpper(),
                },
                {
                    key: 'graduation_degree',
                    label: this.$t('graduation_degree').toUpper(),
                },
                {
                    key: 'faculty',
                    label: this.$t('faculty'),
                },
                {
                    key: 'department',
                    label: this.$t('department'),
                },
                {
                    key: 'year',
                    label: this.$t('graduation_year').toUpper(),
                },
                {
                    key: 'graduation_system',
                    label: this.$t('system_name').toUpper(),
                },
                {
                    key: 'average',
                    label: this.$t('graduation_average').toUpper(),
                },
                {
                    key: 'button',
                    label: '',
                },
            ],
            floatMask: {
                mask: '#####',
                tokens: {
                    '#': {
                        pattern: /[\-\+]?[0-9]*(\.[0-9]+)*([0-9]+)?/,

                    }
                }
            },
            yoksisSearchSwitch: null,
            yoksisData: [],
            yoksisManuelSwitch: false,
            stateData: {},
        }
    },
    created() {

    },
    methods: {
        searchByYoksis(){
            this.yoksisSearchSwitch = true
            this.yoksisData = []
            this.$refs.yoksisSearchModal.$refs.commonModal.show()
            this.yoksisManuelSwitch = false
            this.educationExamForm = {}
            let formData = {
                national_id: this.stateData.personalInfo.national_id,
                pin: this.stateData.personalInfo.pin
            }
            GraduateApplicationService.yokisInfoSearch(formData).then(response => {
                if(typeof response.data.data == 'object'){
                    Object.keys(response.data.data).forEach(index => {
                        if(response.data.data[index].graduation_year) {
                            this.yoksisData.push({
                                graduation_degree: response.data.data[index].unit_type,
                                graduation_degree_code: response.data.data[index].graduation_degree_code,
                                university_id: response.data.data[index].university_id,
                                university_name: response.data.data[index].university_name,
                                faculty: response.data.data[index].faculty,
                                department: response.data.data[index].program,
                                year: response.data.data[index].graduation_year,
                                average: response.data.data[index].diploma_point,
                                graduation_system: response.data.data[index].diploma_point_system,
                                graduation_system_code: response.data.data[index].graduation_system_code
                            })
                        }
                    })
                } else {
                    response.data.data.forEach(data => {
                        if(data.graduation_year) {
                            this.yoksisData.push({
                                graduation_degree: data.unit_type,
                                graduation_degree_code: data.graduation_degree_code,
                                university_id: data.university_id,
                                university_name: data.university_name,
                                faculty: data.faculty,
                                department: data.program,
                                year: data.graduation_year,
                                average: data.diploma_point,
                                graduation_system: data.diploma_point_system,
                                graduation_system_code: data.graduation_system_code
                            })
                        }

                    })
                }
                this.yoksisSearchSwitch = false
            }).catch(e => {
                this.showErrors(e, null)
            })
        },
        async addToEducations() {
            const isValid = await this.$refs.educationExamForm.validate();
            if (isValid) {
                let formData = {
                    graduate_application_id: this.stateData.personalInfo.id,
                    pin: this.stateData.personalInfo.pin,
                    graduation_degree: this.educationExamForm.graduationDegree,
                    university_id: this.educationExamForm.university,
                    faculty: this.educationExamForm.faculty,
                    program: this.educationExamForm.department,
                    graduation_year: this.educationExamForm.year,
                    gpa: this.educationExamForm.average.replace(',','.'),
                    graduation_system: this.educationExamForm.averageSystem
                }

                GraduateApplicationService.saveEducation(formData).then(response => {
                    this.getEducations()
                    this.educationExamForm = {}
                    this.$refs.educationExamForm.reset();
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.yoksisManuelSwitch = false
                }).catch(e => {
                    this.$toast.error(this.$t('api.' + e.data.message));
                })
            }
        },

    }
}
</script>

