<template>
    <div>
        <b-row class="mb-4">
            <b-col md="3">
                <h4 class="mt-1">{{$t('work_history')}}</h4>
            </b-col>
            <b-col md="9">

            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card :header="$t('work_history')" class="soft mb-5">
                <b-table :empty-filtered-text="$t('no_result')"
                         :empty-text="$t('no_result')"
                         bordered
                         responsive
                         :items="graduateData.workingHistories"
                         :fields="workingHistoriesFields"
                         show-empty
                         class="mb-4 table-dropdown no-scrollbar border rounded">
                    <template #cell(buttons)="row">
                        <b-dropdown variant="link btn-sm" boundary="window" no-caret>
                            <template #button-content>
                                <i class="ri-more-fill"></i>
                            </template>
                            <div id="dropdownListHead">
                                <b-dropdown-item @click="$emit('updateClick',['work_history','edit'],row.item)" v-if="checkPermission('documentrequest_sendebys')">
                                    <i class="ri-edit-box-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('edit')}}
                                </b-dropdown-item>
                                <b-dropdown-item @click="deleteHistory(row.item.id)" v-if="checkPermission('documentrequest_sendebys')">
                                    <i class="ri-delete-bin-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('delete')}}
                                </b-dropdown-item>
                            </div>
                        </b-dropdown>
                    </template>
                </b-table>
                <b-button variant="primary" class="mr-2" @click="$emit('updateClick',['work_history','add'])">
                    <i class="ri-add-line mr-1"></i> Ekle
                </b-button>
            </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Template

// Components

//Pages

// Services

// Others

export default {
    props: {
        graduateData: {
            type: Object,
            default: {}
        },
    },

    components: {

    },

    data() {
        return {
            workingHistoriesFields: [
                {
                    key: 'buttons',
                    label: '',
                },
                {
                    key: 'work_start_date',
                    label: this.$t('work_start_date'),
                },
                {
                    key: 'work_leave_date',
                    label: this.$t('work_leave_date'),
                },
                {
                    key: 'corporation',
                    label: this.$t('corporation'),
                },
                {
                    key: 'corp_department',
                    label: this.$t('corp_department'),
                },
                {
                    key: 'working_type',
                    label: this.$t('working_type'),
                },
                {
                    key: 'working_tract',
                    label: this.$t('working_tract'),
                },
            ]
        }
    },
    created() {

    },
    methods: {
        deleteHistory(id){
            this.$swal.fire({
                text: this.$t('are_you_sure_to_delete'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$toast.success(this.$t('successfully_deleted'))
                }
            })
        }
    }
}
</script>

